export default defineNuxtRouteMiddleware((to) => {
  /**
   * https://github.com/nuxt/nuxt/issues/14316
   * Hotfix for the following issue:
   * If a query parameter contains a space, it leads to an infinite redirect on Vercel.
   */
  if (to.fullPath.includes('+')) {
    to.fullPath = to.fullPath.replace(/\+/g, '%20')
  }
})
